<template>
  <img
    ref="imageRef"
    class="transform-gpu"
    :class="[
      {
        ' duration-500 transition-opacity ease-linear': effect === 'fade'
      },
      effect === 'fade' && isVisible ? 'opacity-100' : 'opacity-0'
    ]"
    :src="src"
    :srcset="srcset"
    :sizes="sizes"
    :loading="loading && loading"
    :width="width"
    :height="height"
    :alt="alt"
    @load="onLoad($event)"
    @error="onError($event)"
  />
</template>

<script setup lang="ts">
import { UseIntersectionObserverOptions, useIntersectionObserver } from '@vueuse/core'

export interface Image {
  src: string
  srcset?: string
  width: number
  height: number
  alt: string
  sizes?: string
  loading?: 'eager' | 'lazy'
  effect?: 'fade' | 'none'
  observerOptions?: UseIntersectionObserverOptions
}

const props = withDefaults(defineProps<Image>(), {
  effect: 'fade',
  observerOptions: () => ({
    threshold: 0.5
  })
})

const imageRef = ref<HTMLElement | null>(null)
const isLoaded = ref(false)
const isVisible = ref(false)

defineExpose({
  imageRef
})

const emit = defineEmits(['load'])

function onLoad(e: Event) {
  isLoaded.value = true
  emit('load', e)
}

function onError(e: Event) {
  // eslint-disable-next-line no-console
  console.error(e)
}

const { stop } = useIntersectionObserver(
  imageRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      isVisible.value = true
    }
  },
  props.observerOptions
)

onUnmounted(() => {
  stop()
})
</script>
